import React, { useMemo } from 'react';
import Link from 'next/link';

import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableHeadRow } from './table';
import { IStandings } from '../models/standings';
import { showClubname } from '@/utils/showClubNamePeriodicTitle';

interface IProps {
	clubs: IStandings[];
	legend?: boolean;
	snug?: boolean;
	invertedHeaderColors?: boolean;
	className?: string;
	displayPeriodTitle?: boolean;
	isHistoric?: boolean;
	showCorornaLegend?: boolean;
	year?: string;
	startIndex?: number;
}

export type PromotionTeamsType = { [key: string]: boolean | undefined };

const MAX_ITEMS_WITH_LEGEND = 8;

const getLegendClassName = (club: IStandings, promotionTeams: PromotionTeamsType) => {
	switch (promotionTeams[club.contestantId]) {
		case true:
			return 'legend-blue';
		case false:
			return 'legend-yellow';
		default:
			return undefined;
	}
};

function getClassNameForRankStatus(rankStatus: string | undefined): string {
	switch (rankStatus) {
		case 'green':
			return 'legend-blue';
		case 'red':
			return 'legend-yellow';
		default:
			return '';
	}
}

const resultSplitter = (lastSix: string) => {
	const lettersArray = lastSix?.split('');
	return (
		<div>
			{lettersArray?.map((letter, i) => (
				<span key={i} className={`result-${letter}`}>
					{letter}
				</span>
			))}
		</div>
	);
};

export default function StandingsTable({
	clubs,
	legend,
	snug,
	invertedHeaderColors,
	className,
	displayPeriodTitle,
	isHistoric,
	showCorornaLegend,
	year,
	startIndex = 0,
}: IProps) {
	const promotionTeams = useMemo(() => {
		let teams = clubs.filter((club) => !club.contestantName.includes('Jong'));

		if (year === '2016/2017') {
			teams = teams.filter(
				(club) => !club.contestantName.includes('Eindhoven') && !club.contestantName.includes('Graaf'),
			);
		}

		if (year === '2017/2018') {
			teams = teams.filter(
				(club) => !club.contestantName.includes('Eindhoven') && !club.contestantName.includes('Den Bosch'),
			);
		}

		return teams
			.slice(0, !isHistoric ? MAX_ITEMS_WITH_LEGEND : 9)
			.reduce(
				(acc, club, i) => ({ ...acc, [club.contestantId]: i < (isHistoric ? 1 : 2) }),
				{} as PromotionTeamsType,
			);
	}, [clubs, year, isHistoric]);

	return (
		<div className="overflow-x-auto">
			<Table className={`table-auto overflow-scroll w-full ${className || ''}`} snug={snug}>
				<TableHead>
					<TableHeadRow className={invertedHeaderColors ? 'text-white' : 'text-black-500'}>
						<TableHeadCell className="w-1">
							<span className="sr-only">Legenda</span>
						</TableHeadCell>
						<TableHeadCell className="text-center w-3">#</TableHeadCell>
						<TableHeadCell
							className={
								snug
									? 'font-bold hidden md:table-cell lg:table-cell min-[1280px]:hidden min-[1536px]:table-cell'
									: 'font-bold hidden lg:table-cell'
							}
						></TableHeadCell>
						<TableHeadCell className="text-left">Club</TableHeadCell>
						<TableHeadCell>GS</TableHeadCell>
						<TableHeadCell className="w-[70px] md:w-auto">W/G/V</TableHeadCell>
						<TableHeadCell>P</TableHeadCell>
						<TableHeadCell className={snug ? 'inline-block w-[70px]' : ''}>DV-DT</TableHeadCell>
						<TableHeadCell>DS</TableHeadCell>
						<TableHeadCell className="hidden md:table-cell">Vorm</TableHeadCell>
					</TableHeadRow>
				</TableHead>
				<TableBody className="bg-white">
					{clubs.map((club, i) => (
						<tr key={club.contestantId} className="even:bg-gray-50">
							<>
								{legend ? (
									<TableCell className="w-2">
										<div className={getLegendClassName(club, promotionTeams)} />
									</TableCell>
								) : (
									<TableCell className="w-2">
										<div className={getClassNameForRankStatus(club.rankStatus)} />
									</TableCell>
								)}
							</>
							<TableCell className="text-center w-3">{startIndex + (i + 1)}</TableCell>
							<TableCell nowrap className="text-center min-w-[2.75rem]">
								{club.contestantLogo && (
									<Link href={`/teams/${club.contestantClubSlug}`}>
										<img
											src={club.contestantLogo}
											alt={club.contestantClubName}
											className={
												snug
													? 'inline-block w-6 h-6'
													: 'inline-block sm:w-10 sm:h-10 aspect-square object-contain'
											}
										/>
									</Link>
								)}
							</TableCell>
							<TableCell
								nowrap
								className={
									snug
										? 'font-bold hidden md:table-cell lg:table-cell min-[1280px]:hidden min-[1536px]:table-cell'
										: 'font-bold hidden lg:table-cell'
								}
							>
								<Link href={`/teams/${club.contestantClubSlug}`}>
									{showClubname(club) || club.contestantClubName}
								</Link>
							</TableCell>
							<TableCell nowrap>{club.matchesPlayed}</TableCell>
							<TableCell nowrap>
								{club.matchesWon} / {club.matchesDrawn} / {club.matchesLost}
							</TableCell>
							<TableCell nowrap>{club.points}</TableCell>
							<TableCell nowrap>
								{club.goalsFor} / {club.goalsAgainst}
							</TableCell>
							<TableCell nowrap>{club.goalDifference}</TableCell>
							<TableCell className="hidden md:table-cell" nowrap>
								{resultSplitter(club.lastSix)}
							</TableCell>
						</tr>
					))}
				</TableBody>
			</Table>

			{legend && (
				<div className="block md:flex flex-row justify-between my-8 ">
					<div className="flex flex-row items-start">
						<div className="flex flex-row justify-center items-center mr-4">
							<div className="legend-blue-lg mr-2" />
							<span className={invertedHeaderColors ? 'text-white' : 'text-black-500'}>
								Promotie Eredivisie
							</span>
						</div>
						<div className="flex flex-row justify-center items-center mr-4">
							<div className="legend-yellow-lg mr-2" />
							<span className={invertedHeaderColors ? 'text-white' : 'text-black-500'}>
								Keuken Kampioen Play-Offs
							</span>
						</div>
					</div>
				</div>
			)}

			{displayPeriodTitle && (
				<div className="flex flex-row justify-start lg:my-8 ">
					<div className="my-6 mx-3 md:m-1 flex gap-4">
						<p className={invertedHeaderColors ? 'text-white text-sm' : 'text-black-500'}>
							+ Periodekampioen
						</p>
						<p className={invertedHeaderColors ? 'text-white text-sm' : 'text-black-500'}>
							* 6 punten in mindering
						</p>
					</div>
				</div>
			)}

			{showCorornaLegend && (
				<div className="block md:flex flex-row justify-between my-8 ">
					<div className="my-6 mx-3 md:m-1 flex items-center">
						* Door het annuleren van de competitie in verband met Covid werd er geen kampioen aangewezen en
						werden er geen play-offs gespeeld.
					</div>
				</div>
			)}
		</div>
	);
}
