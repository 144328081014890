import React from 'react';
import Link from 'next/link';
import ReactGA from 'react-ga4';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import { useEligibility } from '@/context/base-context';
import { Button } from './button';

export default function SideBarBanner() {
	const { isEligible, isOptOut, setShowOptOutModal } = useEligibility();
	return (
		<div>
			{isEligible && isOptOut === false && (
				<Swiper
					modules={[Autoplay]}
					loop
					autoplay={{
						delay: 6000,
						disableOnInteraction: false,
					}}
				>
					<SwiperSlide>
						<div className="mb-8 lg:mb-6 relative">
							<Button
								size="small"
								className="absolute top-2 right-2 !px-2 !py-1 !text-[10px]"
								onClick={() => {
									setShowOptOutModal(true);
								}}
							>
								Niet tonen
							</Button>
							<Link
								href="https://gratiskans.vriendenloterij.nl/speel-mee-kkd?utm_source=kkd&utm_medium=display&utm_campaign=202410_3_gratis_loten_v8500&utm_content=banner&utm_term=awareness"
								target="_blank"
								rel="sponsored"
								onClick={() =>
									ReactGA.event({
										category: 'Sponsored Banner',
										action: 'Click',
										label: 'Gratis loten VriendenLoterij',
									})
								}
							>
								<picture>
									<source
										media="(max-width: 600px)"
										srcSet="https://api.keukenkampioendivisie.nl/wp-content/uploads/2024/10/V8500_KKD_1080x1080-1.jpg"
									/>
									<source
										media="(min-width: 601px) and (max-width: 768px)"
										srcSet="https://api.keukenkampioendivisie.nl/wp-content/uploads/2024/10/V8500_KKD_nieuwsbrief_3543x1615-1-scaled.jpg"
									/>
									<source
										media="(min-width: 769px) and (max-width: 1024px)"
										srcSet="https://api.keukenkampioendivisie.nl/wp-content/uploads/2024/10/V8500_KKD_1920x1080.jpg"
									/>
									<source
										media="(min-width: 1025px)"
										srcSet="https://api.keukenkampioendivisie.nl/wp-content/uploads/2024/10/V8500_KKD_1080x1080-1.jpg"
									/>
									<img
										src="https://api.keukenkampioendivisie.nl/wp-content/uploads/2024/10/V8500_KKD_1080x1080-1.jpg"
										alt="Default Image"
									/>
								</picture>
							</Link>
						</div>
					</SwiperSlide>
				</Swiper>
			)}
		</div>
	);
}
